import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';

import { environment, getInformacionComercial, getMatriculas, pago } from 'src/environments/environment';
import { HttpProxy } from '../../../../../shared/services/http.proxy.class';
import { Observable } from 'rxjs';

@Injectable()

export class InformacionComercialService {

    private proxy: HttpProxy;

    constructor(private http: HttpClient) {
        const serviceUri = environment.host;
        this.proxy = new HttpProxy(http, serviceUri);
    }

    validaFinalizacionRues<T>(idFormulario: string, estadoformulario: string) {
        return this.proxy.get<T>(getMatriculas, `/${idFormulario}/validaFinalizadoRues/${estadoformulario}`).pipe(retry(1)).toPromise();
    }

    public getInformacionComercial<T>(idFormulario: string): Promise<T> {
        return this.proxy.get<T>(getInformacionComercial, `/${idFormulario}/informacionComercial`).pipe(retry(1)).toPromise();
    }

    public putInformacionComercial<T>(idFormulario: string, data: any): Promise<T> {
        const service = `${getInformacionComercial}/${idFormulario}/informacionComercial`;
        return this.proxy.put<T>(service, data)
            .pipe(retry(1)).toPromise();
    }

    public deletetReferencias<T>(idFormulario: string, idItem: number, idReferencia: number): Promise<T> {
        const service = `${getInformacionComercial}/${idFormulario}/informacionComercial/${idItem}/${idReferencia}/delete-referencia`;
        return this.proxy.delete<T>(service)
            .pipe(retry(1)).toPromise();
    }

    public deleteBienes<T>(idFormulario: string, idItem: number): Promise<T> {
        const service = `${getInformacionComercial}/${idFormulario}/informacionComercial/${idItem}/delete-bienraiz`;
        return this.proxy.delete<T>(service)
            .pipe(retry(1)).toPromise();
    }

    public labelCheckCircuAfi<T>(): Promise<T> {
        return this.proxy.get<T>(pago, `/text/circulo-afiliados-check`).pipe(retry(1)).toPromise();
    }

    public getFechaRenovacionCircuAfi<T>(): Promise<T> {
        return this.proxy.get<T>(pago, `/param/fecha-renovacion`).pipe(retry(1)).toPromise();
    }

    public getDataChackCircuAfi<T>(idFormulario: string): Promise<T> {
        return this.proxy.get<T>(getInformacionComercial, `/${idFormulario}/informacionComercial/afiliado`).pipe(retry(1)).toPromise();
    }

    public putDataChackCircuAfi<T>(idFormulario: string, data: any): Promise<T> {
        const service = `${getInformacionComercial}/${idFormulario}/informacionComercial/afiliado`;
        return this.proxy.put<T>(service, data)
            .pipe(retry(1)).toPromise();
    }
    
}

